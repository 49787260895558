var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMaxMembersReached)?_c('CyNotification',{attrs:{"theme":"warning","title":_vm.$t('licence.reachedMaxUsersCapacity'),"content":_vm.$t('licence.cannotInviteMembers'),"button-label":_vm.$t('licence.contactYourAdmin')},on:{"click":_vm.navigateToContactAdmin}}):_vm._e(),_c('CyNotification',{attrs:{"theme":"error","content":_vm.errors}}),_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"bulk":_vm.hasBulkModeEnabled,"fetch-available":[
      {
        keyPath: 'members',
        extraParams: [{ 'invitation_state[in]': 'accepted' }],
      },
      {
        keyPath: 'invites',
        extraParams: [{ 'invitation_state[in]': 'pending,declined' }],
      }],"headers":_vm.$static.headers,"transform-items":_vm.$static.transformItems,"link-builder":_vm.getLinkTarget,"searchable-fields":_vm.$static.searchableFields,"key-field":"id"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
      var selected = ref.selected;
return [(!_vm._.isEmpty(selected))?_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('removeMembersBtn', selected.length, { nb: selected.length }))+" ")]):_c('div',[(!_vm._.isEmpty(_vm._.concat(_vm.pendingInvites, _vm.declinedInvites)))?_c('CyButton',{staticClass:"pending-invites__link",attrs:{"variant":"tertiary","theme":"primary","to":{ name: 'pendingInvites' }}},[_vm._v(" "+_vm._s(_vm.$tc('seePendingInvitesBtn', _vm.pendingInvites.length))+" ")]):_vm._e(),_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('InviteUserToOrgMember'),expression:"'InviteUserToOrgMember'"}],attrs:{"icon":"add","loading":_vm.fetchingLicence,"disabled":_vm.isMaxMembersReached,"to":{ name: 'memberInvite' },"data-cy":"invite-to-org"},on:{"click":function($event){return _vm.$gtm.trackEvent(_vm.$static.gtmOrganizationsEvents.organizationsMembersInviteMembers)}}},[_vm._v(" "+_vm._s(_vm.$t('routes.membersInvite'))+" ")])],1),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmRemoveTitle', _vm.toDelete.length, { nb: _vm.toDelete.length }),"action-btn-func":_vm.unassignOrgMembers,"cancel-btn-func":function () { return _vm.$toggle.showDeleteModal(false); },"action-btn-text":_vm.$tc('removeMembersBtn', _vm.toDelete.length, { nb: selected.length }),"modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length, { item: _vm.toDelete[0].name }))}})]),_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(item){return _c('li',{key:item.id},[_c('CyMember',{attrs:{"member":item}})],1)}),0)]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
      var item = ref.props.item;
return [_c('td',[_c('CyAvatar',{attrs:{"item":item,"sm":""}})],1),_c('td',[_vm._v(_vm._s(item.displayName))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_c('CyButton',{staticClass:"font-weight-regular",attrs:{"to":{
            name: 'role',
            params: { roleCanonical: item.role.canonical },
          },"disabled":!_vm.canGoToRole(item),"readonly":!_vm.canGoToRole(item),"variant":"tertiary","theme":"grey"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.role.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(item.mfa_enabled ? _vm.$t('enabled'):_vm.$t('disabled'))+" ")]),_c('td',[(item.invited_by)?_c('CyButton',{attrs:{"to":{
            name: 'member',
            params: { id: String(item.invited_by.id) },
          },"disabled":!_vm.canGoToMember(item),"readonly":!_vm.canGoToMember(item),"variant":"tertiary","theme":"grey","member-link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":item.invited_by}})],1):_vm._e()],1),(item.last_login_at)?_c('td',[_vm._v(" "+_vm._s(_vm.$date.$formatTimeAgo(item.last_login_at))+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }